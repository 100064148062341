import { type JSX } from 'react';
import { useDiveContext } from '../../../context';
import { truncate } from '../../../helpers/helpers';
import { useMediaContext } from '../provider';
import {
  mediaFooterContentCva,
  mediaFooterCva,
  mediaFooterDescriptionCva,
  mediaFooterSubtitleCva,
  mediaFooterTitleCva,
} from './MediaFooter.cva';
import type { MediaFooterProps } from './MediaFooter.types';

export const DESCRIPTION_LENGTH_MAX = 250;

/**
 * Renders `Media` related editorial content
 *
 * | Content       | Overflows to `...` when |
 * |---------------|-------------------------|
 * | `title`       | > 2 lines               |
 * | `subtitle`    | > 1 lines               |
 * | `description` | > 250 characters        |
 *
 * @example
 *
 * ```tsx
 * <MediaFooter
 *   title="Avatar: The Way of Water"
 *   subtitle='Return to Pandora.'
 *   description='Learn the story of the Sully family'
 *  />
 * ```
 */
export function MediaFooter({
  title,
  subtitle,
  description,
  actionableElement,
  fixedHeight = 'none',
}: MediaFooterProps): JSX.Element | null {
  const { isTv } = useDiveContext();
  const { footerPosition = 'default' } = useMediaContext();

  return (
    <div
      className={mediaFooterCva({
        isTv,
        fixedHeight,
      })}
    >
      {(title || subtitle || actionableElement) && (
        <div className={mediaFooterContentCva({ footerPosition })}>
          {(title || subtitle) && (
            <div className="[&>p]:mb-dt-spacing-none">
              {title && (
                <p className={mediaFooterTitleCva({ isTv })}>{title}</p>
              )}
              {subtitle && (
                <p className={mediaFooterSubtitleCva({ isTv })}>{subtitle}</p>
              )}
            </div>
          )}
          {actionableElement}
        </div>
      )}
      {description && (
        <p className={mediaFooterDescriptionCva({ isTv })}>
          {truncate(description, { length: DESCRIPTION_LENGTH_MAX })}
        </p>
      )}
    </div>
  );
}
