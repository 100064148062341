import type { ReactElement } from 'react';
import type {
  DiveIconPosition,
  DiveProps,
  DiveSvgProps,
} from '../../types/Dive.types';
import type { ValueOf } from 'ts-essentials';

export const StickerVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
  Accent: 'accent',
  Emphasis: 'emphasis',
} as const;
export type StickerVariant = ValueOf<typeof StickerVariant>;

export const StickerLabelStyle = {
  Regular: 'regular',
  Semibold: 'semibold',
} as const;
export type StickerLabelStyle = ValueOf<typeof StickerLabelStyle>;

export type StickerProps = {
  /**
   * The label to be displayed within the Sticker
   */
  label: string;

  /**
   * The style of the `label`
   *
   * @default 'regular'
   */
  labelStyle?: StickerLabelStyle;

  /**
   * The variant of the Sticker bearing a specific color scheme
   *
   * @default 'date'
   */
  variant?: StickerVariant;

  /**
   * Takes an SVG icon as a `ReactElement`, to be displayed alongside the `label`
   */
  icon?: ReactElement<DiveSvgProps>;

  /**
   * The position of the `icon` element relative to the `label`
   *
   * @default 'left'
   */
  iconPosition?: DiveIconPosition;
} & DiveProps;
