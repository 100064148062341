import { MediaFooterFixedHeight } from '@dce-front/dive';
import { TitleDisplayMode } from '@dce-front/sdk-hodor';

type GetDiveMediaFooterFixedHeightArg = {
  titleDisplayMode: TitleDisplayMode;
  hasDescription?: boolean;
};

export const getDiveMediaFooterFixedHeight = ({
  titleDisplayMode,
  hasDescription = false,
}: GetDiveMediaFooterFixedHeightArg): MediaFooterFixedHeight => {
  if (hasDescription) {
    return MediaFooterFixedHeight.None;
  }

  switch (titleDisplayMode) {
    case TitleDisplayMode.TitleOnly:
      return MediaFooterFixedHeight.Title;
    case TitleDisplayMode.All:
      return MediaFooterFixedHeight.TitleAndSubtitle;
    default:
      return MediaFooterFixedHeight.None;
  }
};
