import { cva } from 'class-variance-authority';

export const mediaFooterCva = cva('flex-1', {
  variants: {
    isTv: {
      true: '',
      false: '',
    },
    fixedHeight: {
      title: 'overflow-hidden',
      titleAndSubtitle: 'overflow-hidden',
      none: '',
    },
  },
  compoundVariants: [
    {
      isTv: false,
      fixedHeight: 'title',
      className: 'min-h-[2.5rem] max-h-[2.5rem]',
    },
    {
      isTv: false,
      fixedHeight: 'titleAndSubtitle',
      className: 'min-h-[3.75rem] max-h-[3.75rem]',
    },
    {
      isTv: true,
      fixedHeight: 'title',
      className: 'min-h-[4.5rem] max-h-[4.5rem]',
    },
    {
      isTv: true,
      fixedHeight: 'titleAndSubtitle',
      className: 'min-h-[6.625rem] max-h-[6.625rem]',
    },
  ],
});

export const mediaFooterContentCva = cva(
  'flex flex-1 justify-between space-x-dt-spacing-100',
  {
    variants: {
      footerPosition: {
        default: 'items-start',
        responsive: 'items-center dt-breakpoint-sm-start:items-start',
        right: 'items-center',
      },
    },
  },
);

export const mediaFooterTitleCva = cva('mt-dt-spacing-none line-clamp-2', {
  variants: {
    isTv: {
      true: [
        'font-dt-system-hind-title-title-4-regular-tv',
        'text-dt-theme-tv-text-media-block-media-block-title ',
        'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-title-focus ',
      ],
      false: [
        'font-dt-system-hind-title-title-6-regular',
        'text-dt-theme-text-media-block-media-block-title',
      ],
    },
  },
});

export const mediaFooterSubtitleCva = cva('mt-dt-spacing-25 line-clamp-1', {
  variants: {
    isTv: {
      true: [
        'font-dt-system-hind-body-body-2-regular-tv',
        'text-dt-theme-tv-text-media-block-media-block-subtitle',
        'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-subtitle-focus',
      ],
      false: [
        'font-dt-system-hind-title-title-7-regular',
        'text-dt-theme-text-media-block-media-block-subtitle',
      ],
    },
  },
});

export const mediaFooterDescriptionCva = cva('mb-dt-spacing-none', {
  variants: {
    isTv: {
      true: [
        'mt-dt-spacing-100',
        'font-dt-system-hind-body-body-2-regular-tv',
        'text-dt-theme-tv-text-media-block-media-block-description',
        'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-description-focus',
      ],
      false: [
        'mt-dt-spacing-75',
        'font-dt-system-hind-body-body-3-regular',
        'text-dt-theme-text-media-block-media-block-description',
      ],
    },
  },
});
