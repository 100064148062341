import type { ReactElement } from 'react';
import type { ValueOf } from 'ts-essentials';

export const MediaFooterFixedHeight = {
  Title: 'title',
  TitleAndSubtitle: 'titleAndSubtitle',
  None: 'none',
} as const;
export type MediaFooterFixedHeight = ValueOf<typeof MediaFooterFixedHeight>;

export type MediaFooterProps = {
  /**
   * The `Media` resource title, typically a string.
   *
   * Use a `ReactElement` when a line-break must be forced into the title, e.g.: `<>8:50 PM<br/>Avatar: The Way of Water</>`
   */
  title?: string | ReactElement;

  /**
   * The `Media` resource subtitle.
   */
  subtitle?: string;

  /**
   * The `Media` resource description.
   */
  description?: string;

  /**
   * An actionable element that triggers an action relevant to the `Media` resource, e.g.:
   * a start-over button that triggers the playing of a live Media.
   */
  actionableElement?: ReactElement;

  /**
   * Add placeholder height in which elements of the MediaFooter are going to be displayed:
   * - `title`: Only save place for the title
   * - `titleAndSubtitle`: Save place for title and subtitle
   * - `none`: No fixed height. Media footer is fluid.
   *
   * @defaultValue "none"
   */
  fixedHeight?: MediaFooterFixedHeight;
};
